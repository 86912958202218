.project_lists .MuiDataGrid-columnHeaders {
    /* background-color: #e7e7e7; */
    color: var(--color-base);
}

.project_lists .MuiDataGrid-columnHeaders:hover svg {
    color: var(--color-base);
}

.project_lists .MuiDataGrid-withBorderColor {
    border-color: #eaeaea;
    /* border-right: 1px solid rgba(224, 224, 224, 1); */
}

.project_lists .MuiDataGrid-withBorderColor:last-child {
    /* border-right: none; */
}

.project_lists .MuiDataGrid-footerContainer svg {
    color: #6c6c6c;
}

.project_lists .MuiDataGrid-footerContainer .MuiTablePagination-selectLabel {
    margin: 0;
}

.project_lists .MuiDataGrid-footerContainer .MuiTablePagination-displayedRows {
    margin: 0;
}

.project_lists .MuiDataGrid-footerContainer .MuiTablePagination-select {
    background-color: var(--color-btn-orange);
    padding: 10px 30px 10px 10px;
    border-radius: 4px;
    color: #ffffff;
}

.content_lists .MuiDataGrid-footerContainer .MuiTablePagination-select {
    background-color: var(--color-btn-orange);
    padding: 10px 30px 10px 10px;
    border-radius: 4px;
    color: #ffffff;
}

:root {
    --card-line-height: 1.2em;
    --card-padding: 1em;
    --card-radius: 0.5em;
    --radio-border-width: 2px;
    --radio-size: 1.5em;
}

header {
    height: 60px;
}

main {
    /* background-color: #f2f2f2 !important;
  margin-top: 60px;
  padding: 0px !important;
  overflow: hidden;
  box-shadow: 0px 0px 10px 0px #e7e7e7;
  min-height: 100% !important; */
    background-color: #ffffff !important;
    margin-top: 6vh;
    padding: 0px !important;
    overflow: hidden;
    box-shadow: 0px 0px 10px 0px #e7e7e7;
    min-height: 94vh !important;
}

.project_lists {
    border-radius: 20px;
    background: #ffffff;
    margin: 0;
    padding: 10px;
    min-height: calc(100vh - 60px);
}

.marginTop {
    margin-top: 2rem;
}

.__left {
    /* background-color: #f1f0f0; */
    /* height: 450px; */
    padding: 0 10px 0 0;
    border-radius: 2%;
    overflow-y: auto;
    height: 65vh;
    /* border-right: 1px solid #ccc; */
}

.__left input {
    height: 55px;
}

.page-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
    margin-bottom: 0px;
}

.page-title .product-list-dropdown {
    height: 50px;
    max-width: 20%;
    background-color: var(--color-white);
    color: var(--color-black);
    box-shadow: 0 2px 5px 0px var(--color-darker-gray);
}

.page-title .product-list-dropdown:focus {
    box-shadow: none;
    border-color: var(--color-blue);
    box-shadow: 0 2px 5px 0px var(--color-darker-gray);
}

.available_lists {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-radius: 10px;
    gap: 10px;
    margin: 0 0 10px 0;
    cursor: pointer;
    user-select: auto;
}

.online-dot {
    height: 10px;
    width: 10px;
    background-color: green;
    border-radius: 50%;
    display: inline-block;
    box-shadow: 0 0 5px rgba(0, 128, 0, 0.6); /* Green shadow */
}

.leftBorder {
    border-left: 1px solid lightgray;
    border-radius: 0px;
    padding: 10px;
}

.available_lists:active {
    background-color: var(--color-gray);
}

.available_lists:hover {
    background-color: var(--color-gray);
}

.available_lists .__avatar img {
    width: 40px;
    border: 2px solid var(--color-white);
    border-radius: 50%;
    /* box-shadow: 0px 0px 30px 0px var(--color-dark-gray); */
}

.chat_page {
    /* margin-top: 20px; */
}

.available_lists .__details h5 {
    margin-bottom: 5px;
    font-size: 16px;
    font-weight: 400;
}

.new_line {
    white-space: pre-wrap;
}

.available_lists .__details p {
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
}

.available_lists .__details p span {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 5px;
}

.available_lists .__details p span.active {
    background-color: var(--color-online);
}

.available_lists .__details p span.away {
    background-color: var(--color-offline);
}

.__right .chat_header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 10px;
    gap: 10px;
}

.__right .chat-history {
    background-color: var(--color-white);
    height: 55vh;
    padding: 0 10px;
    border-radius: 10px;
    overflow-y: auto;
    overflow-x: hidden;
}

.__right .chat_header .__avatar img {
    width: 40px;
    border: 2px solid var(--color-white);
    border-radius: 50%;
    box-shadow: 0px 0px 30px 0px var(--color-dark-gray);
}

.__right .chat_header .__details h5 {
    margin-bottom: 5px;
    font-size: 18px;
    font-weight: 500;
}

.__right .chat_header .__details p {
    margin-bottom: 0;
    font-size: 15px;
    font-weight: 500;
}

.me h4,
.you h4 {
    font-size: 0.9rem !important;
    color: var(--color-blue);
    font-weight: 400;
    line-height: 1.6;
}

.me {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 15px;
    margin-bottom: 10px;
}

.custom-tooltip {
    visibility: hidden;
    background-color: #959595;
    color: black;
    text-align: center;
    border-radius: 5px;
    padding: 5px;
    position: absolute;
    z-index: 9;
    bottom: 150%;
    /* white-space: nowrap; */
    opacity: 0;
    transition: opacity 0.3s;
    width: 250px;
    text-wrap: wrap;
    word-wrap: break-word;
    color: #ffffff;
    right: 1px;
}

.custom-tooltip p,
.custom-tooltip span {
    color: #ffffff;
}

.popup-arrow {
    position: absolute;
    top: 98%;
    left: 7%;
    transform: translateX(-50%);
    width: 10px;
    height: 10px;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid #959595;
    left: 93%;
}

.tooltip-icon1 .custom-tooltip {
    visibility: visible;
    opacity: 1;
    text-decoration: none;
    font-weight: 600;
    box-shadow: 2px 3px 10px #959595;
}

.me h4 {
    background: var(--color-chat-right);
    padding: 15px;
    border-radius: 5px;
    position: relative;
    width: -moz-fit-content;
    width: fit-content;
}

.me h4::before {
    content: "";
    position: absolute;
    left: -10px;
    top: 40%;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid var(--color-chat-right);
}

.me img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.you {
    display: flex;
    align-items: center;
    justify-content: right;
    gap: 15px;
}

.you h4 {
    background: var(--color-chat-left);
    padding: 15px;
    border-radius: 5px;
    position: relative;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 0 0 auto;
}

.you h4::before {
    content: "";
    position: absolute;
    right: -8px;
    top: 40%;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    transform: rotate(180deg);
    border-bottom: 10px solid transparent;
    border-right: 10px solid var(--color-chat-left);
}

.you img {
    width: 40px;
}

p.typing-line {
    color: var(--color-black);
    width: 100%;
    text-align: left;
    height: auto;
}

span.seen-text {
    font-size: 0.8rem;
    color: #0d1d31;
    display: block;
    padding-bottom: 5px;
    position: relative;
}

.messageBox textarea {
    border: 0;
    border-bottom: 1px solid var(--color-dark-gray);
    border-radius: 0;
    background-color: var(--color-bg);
}

.messageBox textarea:focus {
    box-shadow: none;
    /* background-color: #f5f5f5; */
    background-color: var(--color-bg);
    border-bottom: 2px solid var(--color-blue);
}

.chat-setting {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100% - 40px);
}

.form-card {
    /* background-color: var(--color-white);
  padding: 0;
  border-radius: 15px; */
}

.content-form-card {
    background-color: var(--color-white);
    padding: 5px;
    border-radius: 15px;
}

form.setting-form {
    background-color: var(--color-white);
    /* padding: 0px 20px 0px 25px; */
    padding: 20px 20px 0px 35px;
    border-radius: 15px;
    /* width: 70%;
  margin: 0 auto; */
}

form.setting-form label {
    font-size: 14px;
    margin-bottom: 5px;
    font-weight: 500;
}

form.setting-form input.form-control,
form.setting-form textarea.form-control,
form.setting-form select.form-select {
    border-radius: 0;
    /* height: 55px; */
    margin-bottom: 5px;
    border: 0;
    border-bottom: 1px solid var(--color-chat-left);
    background-color: transparent;
}

form.setting-form input.form-control:focus,
form.setting-form select.form-select:focus,
form.setting-form textarea.form-control:focus {
    box-shadow: none;
    border-bottom: 1px solid var(--color-btn-orange);
}

form.setting-form span {
    font-size: 14px;
    font-weight: 400;
}

button.btn.save_btn {
    background: var(--color-btn-orange);
    height: 50px;
    width: auto;
    color: var(--color-white);
    font-weight: 500;
    font-size: 1em;
    border: 0;
    padding: 0 20px;
    border-radius: 5px;
}

button.btn.save_btn:focus {
    box-shadow: none;
}

.multiple_emails .react-multi-email {
    border: 0;
    border-radius: 0;
    border-bottom: 1px solid var(--color-chat-left);
    background-color: transparent;
    height: auto;
    margin: 8px;
}

.multiple_emails .react-multi-email:focus {
    box-shadow: none;
    border-bottom: 1px solid var(--color-btn-orange);
}

.grid {
    display: grid;
    grid-gap: var(--card-padding);
    max-width: 100%;
}

@media (min-width: 42em) {
    .grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

.card {
    background-color: var(--color-white);
    border-radius: var(--card-radius);
    position: relative;
}

.card:hover {
    box-shadow: 0px 0px 0px var(--color-black-overlay);
}

.radio {
    font-size: inherit;
    margin: 0;
    position: absolute;
    right: calc(var(--card-padding) + var(--radio-border-width));
    top: calc(var(--card-padding) + var(--radio-border-width));
}

@supports (-webkit-appearance: none) or (-moz-appearance: none) {
    .radio {
        -webkit-appearance: none;
        -moz-appearance: none;
        background: var(--color-white);
        border: var(--radio-border-width) solid var(--color-gray);
        border-radius: 50%;
        cursor: pointer;
        height: var(--radio-size);
        outline: none;
        transition:
            background 0.2s ease-out,
            border-color 0.2s ease-out;
        width: var(--radio-size);
    }

    .radio::after {
        border: var(--radio-border-width) solid var(--color-white);
        border-top: 0;
        border-left: 0;
        content: "";
        display: block;
        height: 0.75rem;
        left: 25%;
        position: absolute;
        top: 50%;
        transform: rotate(45deg) translate(-50%, -50%);
        width: 0.375rem;
    }

    .radio:checked {
        background: var(--color-blue);
        border-color: var(--color-blue);
    }

    .card:hover .radio {
        border-color: var(--color-dark-gray);
    }

    .card:hover .radio:checked {
        border-color: var(--color-blue);
    }
}

.plan-details {
    border: var(--radio-border-width) solid var(--color-gray);
    border-radius: var(--card-radius);
    cursor: pointer;
    display: flex;
    flex-direction: column;
    padding: var(--card-padding);
    transition: border-color 0.2s ease-out;
}

.plan-details img {
    width: 20px;
    height: 20px;
    object-fit: contain;
    margin-right: 10px;
}

.radio:checked ~ .plan-details {
    border-color: var(--color-blue);
}

.right-float-button {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    padding: 12px;
}

.send-button {
    background-color: var(--color-btn-orange);
    color: var(--color-white);
    border-radius: 5px;
    width: 100px;
    height: 40px;
    border: 0;
}

.integration-button {
    color: var(--color-white);
    background-color: var(--color-icon-orange);
    border-radius: 5px;
    width: 180px;
    height: 40px;
    border: 0;
    margin-right: 10px;
}

.fail-button {
    background-color: var(--error-300);
    padding-top: 3px;
}

.success-button {
    background-color: var(--color-success);
}

.row-button {
    border-color: #1a202e;
    background-color: var(--color-white);
    border-radius: 5px;
    padding: 5px;
    font-size: 14px;
}

.css-i4bv87-MuiSvgIcon-root {
    font-size: 22px;
}

.setting-send-button {
    background-color: var(--color-btn-orange);
    color: var(--color-white);
    border-radius: 5px;
    width: 150px;
    height: 40px;
    border: 0;
}

.card-pointer {
    cursor: pointer;
}

.chatbotIconSize {
    height: 40px;
    width: 100%;
}

.csvIconSize {
    height: 40px;
    width: 100%;
    filter: opacity(0.5);
}

.pdfIconSize {
    height: 45px;
    width: 100%;
    filter: opacity(0.5);
}

.linkIconSize {
    height: 30px;
    width: 100%;
    margin: 5px 0px 0px 10px;
    filter: opacity(0.5);
}

.txtIconSize {
    height: 30px;
    width: 100%;
    margin: 5px 0px 0px 10px;
    filter: opacity(0.5);
    margin: auto;
}

.faqIconSize {
    height: 35px;
    width: 100%;
    margin: 5px 0px 0px 10px;
    filter: opacity(0.5);
}

.promptOption {
    font-weight: normal;
    display: block;
    word-wrap: break-word;
    min-height: 1.2em;
    padding: 0px 2px 1px;
}

#chat-bot-ui {
    position: fixed;
    bottom: 2%;
    right: 1%;
    width: 70px;
    height: 70px;
    border-radius: 35px;
    font-size: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9;
}

.popup-bot-title {
    margin-left: 0px;
    font-size: 18px;
}

.textElipses {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.cardRow {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    margin-left: -8px;
    width: 90%;
}

.formDisplay {
    display: flex;
}

.margin10Right {
    margin-right: 10px;
}

.iconFlex {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    margin-bottom: 0 !important;
    margin-right: 15px;
    margin-left: 2.2rem;
}

.validationMsg {
    font-size: 14px;
    color: #d53131;
}

.titleAlign {
    padding-left: 15px;
}

.categoryImg {
    height: 20px;
    width: 20px;
    margin-right: 10px;
}

.categoryCardAlign {
    display: flex;
    flex-flow: nowrap;
}

.avatar {
    display: flex;
    justify-content: center;
}

.avatarAlign {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fafafa;
    border: 1px solid #cacbcd;
    border-radius: 50%;
    height: 100px;
    width: 100px;
    position: relative;
}

.avatarImg {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 50%;
}

.fileUpload {
    background: var(--color-btn-orange);
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    border-radius: 15px;
    color: #fff;
    font-size: 1em;
    font-weight: bold;
    margin: 1.25em auto; /*20px/16px 0*/
    padding: 10px; /*14px/16px*/
    position: absolute;
    bottom: -45px;
    text-align: center;
    width: 50px;
    cursor: pointer;
}

.fileUpload input.upload {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    padding: 0;
    font-size: 20px;
    cursor: pointer;
    opacity: 0;
    width: 148px;
    height: 46px;
    cursor: pointer;
}

.deleteUpload {
    position: absolute;
    top: -14px;
    right: -25px;
    border-radius: 25%;
}

.crossBtnColor {
    background: #d53131;
    color: white;
    height: 10px;
}

.contentTitle {
    text-align: left;
    padding-left: 20px;
    word-wrap: break-word;
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 10px;
}

.inboxValue {
    text-align: center;
    word-wrap: break-word;
    font-size: 15px;
    font-weight: 500;
}

.contentFlex {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.stackContentButton {
    display: flex;
}

.promptDilog .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    max-width: 1200px !important;
    width: 1200px !important;
}

.actionBtnMargin {
    margin-right: 10px;
}

.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
    width: 450px;
}

.exportBtn {
    margin-bottom: 10px;
    text-align: end;
}

.inbox-title {
    margin-left: 10px;
}

.storeIconAlign {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.checkBoxFlex {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.chatbotLoader {
    display: flex;
    justify-content: center;
    align-items: center;
}

.tabs button {
    border: 1px solid #f3f3f3;
    border-radius: 50px;
}

.tabs button.Mui-selected {
    background-color: var(--color-btn-orange);
    color: var(--color-white);
}

.tabs .MuiTabs-flexContainer {
    gap: 10px;
    display: flex;
    justify-content: end;
}

.tabs .MuiTabs-indicator {
    height: 0;
}

.textAreaHeight {
    height: 100px;
}

.textAreaHeight50 {
    height: 50px;
}

.deleteBtnDisplay {
    display: flex;
    justify-content: end;
}

.css-1e6y48t-MuiButtonBase-root-MuiButton-root {
    white-space: nowrap;
    width: 110px !important;
    padding: 0px 70px 0px 70px !important;
}

.boxPadding {
    padding: 10px;
}

.boxMargin {
    margin-top: -20px;
}

.bg-class .MuiBox-root {
    padding: 15px 15px !important;
    background: #ffffff;
    /* border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px; */
}

.actionBtn {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
}

.versionDropdown {
    width: 300px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.no_chats {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 350px);
}

.noChatImage {
    width: 100%;
    height: 100%;
}

.clientTitle {
    display: flex;
    margin: 20px 0px 10px 7px;
}

._format {
    text-align: left;
    display: block;
    white-space: pre-wrap;
}

.css-i4bv87-MuiSvgIcon-root {
    height: 15px !important;
}

._parameter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 42px;
}

.color {
    width: 50px;
    height: 50px;
    border: 1px solid lightgray;
    cursor: pointer;
    border-radius: 15%;
}

.pickerPosition {
    position: absolute;
    top: 5%;
    right: 20%;
}

.actionItem {
    padding: 10px;
    background-color: #efefef;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.themeLabelSize {
    font-weight: 500;
}

.chatsettingTab {
    padding-left: 20px;
}

._leftItem {
    margin-right: 160px;
}

#chat-bot {
    position: fixed;
    bottom: 2%;
    right: 1%;
    width: 70px;
    height: 70px;
    border-radius: 35px;
    font-size: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9;
    background: linear-gradient(135deg, rgb(42, 39, 218) 0%, rgb(0, 204, 255) 100%);
}

#send-button {
    position: absolute;
    right: 5px;
    transform: translateY(-50%);
    top: 50%;
    width: 50px;
    height: 50px;
    font-size: 16px;
    border-radius: 50%;
    display: flex;
    border: none;
    align-items: center;
    justify-content: center;
    /* background: linear-gradient(
    135deg,
    rgb(42, 39, 218) 0%,
    rgb(0, 204, 255) 100%
  ); */
}

.chatModulePopup .chat-area {
    /* position: fixed; */
    z-index: 9;
    bottom: 100px;
    right: 20px;
    width: 430px;
    margin: 0 auto;
    font-size: 16px;
    padding: 0;
    background: #fff;
    border-radius: 20px;
    border: 2px solid #efefef;
    overflow: hidden;
    height: 570px;
}

.chatModulePopup .chat-area .chatModuleHeading {
    /* background-image: linear-gradient(135deg, rgb(42, 39, 218) 0%, rgb(0, 204, 255) 100%); */
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 10px 5px 20px 5px;
    align-items: center;
}

.chatModulePopup .chat-area .wave-bottom {
    /* background-image: linear-gradient(0deg, #5ed9ff 0%, #416ad9 100%); */
    /* background-image: linear-gradient(
    135deg,
    rgb(42, 39, 218) 0%,
    rgb(0, 204, 255) 100%
  ); */
    padding: 15px;
    position: relative;
}

.chatModulePopup .chat-area .wave-bottom::after {
    content: "";
    position: absolute;
    width: calc(100% + 10px);
    bottom: -8px;
    left: -5px;
    border-image-source: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzNzIgMTUiPgogIDxwYXRoIGQ9Ik0zNDkuOCAxLjRDMzM0LjUuNCAzMTguNSAwIDMwMiAwaC0yLjVjLTkuMSAwLTE4LjQuMS0yNy44LjQtMzQuNSAxLTY4LjMgMy0xMDIuMyA0LjctMTQgLjUtMjggMS4yLTQxLjUgMS42Qzg0IDcuNyA0MS42IDUuMyAwIDIuMnY4LjRjNDEuNiAzIDg0IDUuMyAxMjguMiA0LjEgMTMuNS0uNCAyNy41LTEuMSA0MS41LTEuNiAzMy45LTEuNyA2Ny44LTMuNiAxMDIuMy00LjcgOS40LS4zIDE4LjctLjQgMjcuOC0uNGgyLjVjMTYuNSAwIDMyLjQuNCA0Ny44IDEuNCA4LjQuMyAxNS42LjcgMjIgMS4yVjIuMmMtNi41LS41LTEzLjgtLjUtMjIuMy0uOHoiIGZpbGw9IiNmZmYiLz4KPC9zdmc+Cg==);
    border-image-slice: 0 0 100%;
    border-image-width: 0 0 15px;
    border-image-repeat: stretch;
    border-width: 0px 0px 15px;
    border-bottom-style: solid;
    border-color: initial;
    border-top-style: initial;
    border-left-style: initial;
    border-right-style: initial;
}

.chatModulePopup .chat-area .wave-bottom p {
    margin-bottom: 0;
    color: #ffffff;
}

.chatModulePopup .chat-area .chatModuleHeading h2 {
    /* color: #fff; */
    margin-bottom: 0;
    font-size: 1.124rem;
    letter-spacing: 0;
    font-weight: 400;
    text-transform: capitalize;
}

.chatModulePopup .chat-area .chatModuleHeading h2 span {
    font-size: 0.864rem;
}

.chatModulePopup .chat-area .chatModuleHeading svg {
    fill: #fff;
    width: 1.5rem;
    cursor: pointer;
}

.chatModulePopup .chat-area .chat-date-tag {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 25px;
}

.chatModulePopup .chat-area .chat-date-tag span {
    background-color: #e9e9e9;
    width: auto;
    padding: 10px 20px;
    border-radius: 50px;
    font-size: 0.854rem;
    color: #958686;
}

.chatModulePopup .chat-area .you h4 {
    font-size: 0.964rem;
    font-weight: 400;
    /* color: #222222; */
    background-color: transparent;
    padding: 0;
}

.chatModulePopup .chat-area .me h4 {
    font-size: 0.964rem;
    font-weight: 400;
    /* color: #ffffff; */
    text-align: left;
    background-color: transparent;
    padding: 0;
    word-break: break-all;
}

.chatModulePopup .chat-area .you {
    /* background: #f0f2f7; */
    padding: 10px;
    border-radius: 15px;
    position: relative;
    width: fit-content;
    left: 0;
    top: 0;
}

.chatModulePopup .chat-area .me {
    /* background-image: linear-gradient(135deg, rgb(42, 39, 218) 0%, rgb(0, 204, 255) 100%); */
    border-radius: 15px;
    position: relative;
    width: fit-content;
    margin: 0 0 0 auto;
    padding: 10px 15px;
}

.chatModulePopup .chat-area .msg-left-container {
    padding: 10px;
    display: flex;
    gap: 10px;
}

.chatModulePopup .chat-area span.time {
    font-size: 10px;
    font-weight: 600;
    display: none;
}

.chatModulePopup .chat-area .email-transcript-button-container {
    display: none;
}

.chatModulePopup .chat-area .chat-input {
    padding: 0;
    margin: 0;
    background: #e7ebf1b5;
    gap: 0;
    border-top: 1px solid #e9e9e9;
    position: relative;
}

.chatModulePopup .chat-area textarea#message {
    border: 0px;
    width: 100%;
    font-size: 17px;
    padding: 25px 10px 15px;
    resize: none;
    line-height: 24px;
    overflow-x: hidden;
}
.chatModulePopup .chat-area #message:focus {
    outline: 0;
    box-shadow: none;
}

/* 12-sep 2023 css */

.chatModulePopup .chat-area #chat-window {
    height: 285px;
    overflow: auto;
    overflow-x: hidden;
    /* background: #ffffff; */
    padding: 5px;
    display: flex;
    flex-direction: column;
    /* justify-content: flex-end; */
}

.chatModulePopup .chat-area #chatMsgOUtput button {
    font-size: 1.1rem;
}

.chatModulePopup .chat-area #chatMsgOUtput p {
    padding: 2px 0px;
    border-bottom: 1px solid #e9e9e9;
    color: #555;
    font-size: 1.2rem;
}

.chatModulePopup .chat-area #chatMsgOUtput strong {
    color: #575ed8;
}

.chatModulePopup .chat-area .chatMsg {
    margin-bottom: 1rem;
}

.chatModulePopup .chat-area .chatMsg span {
    margin-left: 10px;
}

.chatModulePopup .chat-area #chatbot-typing-msg p,
.chatModulePopup .chat-area .chatMsg span {
    padding: 5px 0px;
    color: #555;
    font-size: 1rem;
    margin-bottom: 0;
}

.chatModulePopup .chat-area #chatbot-typing-msg strong {
    color: #575ed8;
}

.chatModulePopup .chat-area label {
    box-sizing: border-box;
    display: block;
    padding: 10px 20px;
}

/* .chat-input {
  padding: 10px 20px;
  margin: 0px;
  background: #fff;
  gap: 5px;
  position: relative;
} */

.chatModulePopup .chat-area .chatbot-typing {
    display: flex;
    background: #fff;
    margin-left: 10px;
}

.chatModulePopup .chat-area .chatbot-typing .chatbot-dot {
    animation: mercuryTypingAnimation 1.8s infinite ease-in-out;
    background-color: #6c71ad;
    border-radius: 50%;
    height: 7px;
    margin-right: 4px;
    vertical-align: middle;
    width: 7px;
    display: inline-block;
}

.chatModulePopup .chat-area.chatbot-typing .chatbot-dot:nth-child(1) {
    animation-delay: 200ms;
}

.chatModulePopup .chat-area .chatbot-typing .chatbot-dot:nth-child(2) {
    animation-delay: 300ms;
}

.chatModulePopup .chat-area .chatbot-typing .chatbot-dot:nth-child(3) {
    animation-delay: 400ms;
}

.chatModulePopup .chat-area .chatbot-typing .chatbot-dot:last-child {
    margin-right: 0;
}

@keyframes mercuryTypingAnimation {
    0% {
        transform: translateY(0px);
        background-color: #6c71ad;
    }

    28% {
        transform: translateY(-7px);
        background-color: #9ea2ca;
    }

    44% {
        transform: translateY(0px);
        background-color: #b5b9d9;
    }
}

/* #message {
  border-radius: 50px;
  width: 100%;
  background: #fff;
  padding: 15px;
  border: 1px solid #ccc;
  font-size: 16px;
  height: 40px;
  margin-left: 15px;
} */

.chatModulePopup .chat-area .chatbot-property-card {
    margin-bottom: 10px;
}

.chatModulePopup .chat-area .chatbot-property-title {
    font-size: 20px;
}

.chatModulePopup .chat-area .chatbot-property-description {
    font-size: 13px;
}

.chatModulePopup .chat-area .chatbot-property-view-button {
    text-align: right;
}

.chatModulePopup .chat-area .chatbot-submit-button {
    width: 100px;
}

.chatModulePopup .chat-area .chatbot-ta-c {
    text-align: center;
}

.chatModulePopup .chat-area .tooltip-container {
    position: relative;
    display: inline-block;
    opacity: 1;
    text-align: right;
    width: 100%;
}
.chatModulePopup .chat-area .tooltip-container .tooltiptext {
    visibility: hidden;
    width: 280px;
    bottom: 100%;
    left: 50%;
    margin-left: -60px;
    background-color: black;
    color: #fff;
    text-align: left;
    border-radius: 6px;
    padding: 5px 0;

    position: absolute;
    z-index: 1;
}

.chatModulePopup .chat-area .tooltip-container:hover .tooltiptext {
    visibility: visible;
}

.chatModulePopup .chat-area .view-more-properties {
    text-align: right;
}

.chatModulePopup .chat-area .idle-timeout-options-container {
    display: flex;
}

.chatModulePopup .chat-area .idle-timeout-options {
    margin-right: 10px;
    background-color: #0d6efd;
    color: #fff;
    border: 0px;
    border-radius: 5px;
    padding: 3px 15px;
}

.chatModulePopup .chat-area .idle-timeout-options:disabled {
    background-color: #62a1fe;
}

.chatModulePopup .chat-area .msg-right-container {
    margin-left: 5px;
}

.chatModulePopup .chat-area span.bot {
    position: relative;
}

.chatModulePopup .chat-area span.bot3 {
    position: relative;
}

.chatModulePopup .chat-area span.bot::after {
    content: "";
    background-color: #52eb52;
    width: 15px;
    height: 15px;
    position: absolute;
    right: 0;
    top: 0;
    border-radius: 50px;
    border: 2px solid #fff;
}

.chatModulePopup .chat-area span.bot3::after {
    content: "";
    background-color: #52eb52;
    width: 15px;
    height: 15px;
    position: absolute;
    right: 0;
    border-radius: 50px;
    border: 2px solid #fff;
}

.chatModulePopup .chat-area span.bot i {
    color: #44444487;
    font-size: 18px;
}

.chatModulePopup .chat-area img.bot-img1 {
    border-radius: 50% !important;
    width: 40px;
    height: 40px;
    min-width: 30px;
}

.chatModulePopup .chat-area img.bot-img {
    border-radius: 50% !important;
    width: 40px;
    height: 40px;
    min-width: 30px;
    margin-right: 10px;
}

.chatModulePopup .chat-area img.bot-img3 {
    border-radius: 50% !important;
    width: 40px;
    height: 40px;
    min-width: 30px;
}

.chatModulePopup .chat-area img.user-img {
    border-radius: 50% !important;
    width: 40px;
    height: 40px;
    min-width: 30px;
    margin-right: 10px;
}

.chatModulePopup .chat-area .msg-card {
    display: flex;
    width: 80% !important;
}

.mb-10 {
    margin-bottom: 10px;
}

.p10 {
    padding: 10px;
}

.text-end {
    display: flex;
    width: 100% !important;
    padding-left: 20%;
    justify-content: flex-end;
}

.chatModulePopup .chat-area .you span i {
    color: #fff;
}

.chatModulePopup .chat-area .you h4::before,
.chatModulePopup .chat-area .me h4::before {
    content: unset !important;
}

.chatModulePopup .chat-area .chatbot-rating-stars-rate {
    height: 46px;
    padding: 0 10px;
}
.chatModulePopup .chat-area .chatbot-rating-stars-rate:not(:checked) > input.chatbot-input {
    position: absolute;
    top: -9999px;
}
.chatModulePopup .chat-area .chatbot-rating-stars-rate:not(:checked) > label.chatbot-label {
    float: right;
    width: 1em;
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;
    font-size: 30px;
    color: #ccc;
}
.chatModulePopup .chat-area .chatbot-rating-stars-rate:not(:checked) > label.chatbot-label:before {
    content: "★ ";
}
.chatModulePopup .chat-area .chatbot-rating-stars-rate > input.chatbot-input:checked ~ label.chatbot-label {
    color: #ffc700;
}
.chatModulePopup .chat-area .chatbot-rating-stars-rate:not(:checked) > label.chatbot-label:hover,
.chatModulePopup .chat-area .chatbot-rating-stars-rate:not(:checked) > label.chatbot-label:hover ~ label.chatbot-label {
    color: #deb217;
}
.chatModulePopup
    .chat-area.chatModulePopup
    .chat-area.chatbot-rating-stars-rate
    > input.chatbot-input:checked
    + label.chatbot-label:hover,
.chatModulePopup
    .chat-area.chatModulePopup
    .chat-area.chatbot-rating-stars-rate
    > input.chatbot-input:checked
    + label.chatbot-label:hover
    ~ label.chatbot-label,
.chatModulePopup .chat-area.chatbot-rating-stars-rate > input.chatbot-input:checked ~ label.chatbot-label:hover,
.chatModulePopup
    .chat-area
    .chatbot-rating-stars-rate
    > input.chatbot-input:checked
    ~ label.chatbot-label:hover
    ~ label.chatbot-label,
.chatModulePopup
    .chat-area
    .chatbot-rating-stars-rate
    > label.chatbot-label:hover
    ~ input.chatbot-input:checked
    ~ label.chatbot-label {
    color: #c59b08;
}

.chatModulePopup .chat-area .chatbot-label {
    padding: unset;
}

.chatModulePopup .chat-area .form-label-chatbot {
    padding-left: 0;
}

.chatModulePopup .chat-area .chatbot-trigger-email-transcript {
    border-radius: 50%;
}

.chatModulePopup .chat-area .email-transcript-modal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    visibility: hidden;
    transform: scale(1.1);
    transition:
        visibility 0s linear 0.25s,
        opacity 0.25s 0s,
        transform 0.25s;
}
.chatModulePopup .chat-area .email-transcript-modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 1rem 1.5rem;
    width: 24rem;
    border-radius: 0.5rem;
}

.chatModulePopup .chat-area .email-transcript-close-button {
    float: right;
    width: 1.5rem;
    line-height: 1.5rem;
    text-align: center;
    cursor: pointer;
    border-radius: 0.25rem;
    background-color: lightgray;
}
.chatModulePopup .chat-area .email-transcript-close-button:hover {
    background-color: darkgray;
}

.chatModulePopup .chat-area .show-email-transcript-modal {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
    transition:
        visibility 0s linear 0s,
        opacity 0.25s 0s,
        transform 0.25s;
    z-index: 999;
}

.chatModulePopup .chat-area .chatbot-flex-container {
    display: flex;
    align-items: center;
}

.chatModulePopup .chat-area .chatbot-form-element {
    width: 100%;
}

.chatModulePopup .chat-area .rating-container {
    display: flex;
    flex-direction: column;
}

.sendBtnSvg {
    fill: #000;
}

.customValidationRow {
    background: #fff;
    padding: 10px 0px 10px 0px;
    margin-inline: auto;
    border-left: 0.5px solid #fd8218;
}

.dataParameterIcon {
    color: #fff;
    background-color: #fd8218;
    padding: 5px;
    /* border: 0.5px solid #fd8218; */
    border-radius: 5px;
}

.textNoBreak {
    white-space: nowrap;
}

.dateTimeFlex {
    display: flex;
    flex-direction: row;
}

.dateTimeInput {
    padding: 7px;
    font-size: 20px;
}
.ant-picker .ant-picker-suffix {
    color: #fd8218;
}

.ant-picker-input > input::placeholder {
    color: #000;
    font-size: 15px;
}

.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
    width: auto !important;
}

.ant-picker-input input::placeholder {
    color: #0000;
}

.invalidEmailMsg {
    padding-left: 15px;
    font-size: 14px;
    color: red;
}

.rightSpace {
    margin-right: 20px;
}

.setting-form {
    padding: 0px 20px 0px 35px !important;
    margin-top: 5px !important;
}
.tokenTextAlign {
    color: #fd8218;
    padding-left: 20px;
}

.tokenFlex {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.css-9l5vo-MuiCollapse-wrapperInner {
    text-align: left;
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root {
    box-shadow: none !important;
    border-radius: 10px !important;
    /* box-shadow: 1px 1px 0.5px  rgb(212, 204, 204) !important; */
}

.quesTitle {
    font-weight: 400 !important;
    font-family: "Poppins" !important;
}

.qa-section {
    background: #ffffff;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 15px 10px;
    min-height: calc(100vh - 220px);
}

.tabs-menu button {
    background: white;
    border: none !important;
    box-shadow: none !important;
}

.form-card {
    /* padding: 10px; */
}

.qaIcon {
    transform: scale(1.8);
}

.qaActionIcon {
    transform: scale(1.4);
}

.versionDisplay {
    display: flex;
    align-items: center;
}

.css-19kzrtu {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    /* min-height: calc(100vh - 184px); */
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root:last-of-type {
    /* filter: drop-shadow(2px 2px 4px lightgrey); */
    box-shadow: 0 2px 5px 0px var(--color-darker-gray) !important;
}

.linkBtn {
    margin-right: 10px;
}

.contentListHeight {
    height: calc(100vh - 300px);
    width: "100%";
    background: "white";
}

.conversation-section {
    background: white;
    padding-top: 10px;
    margin: 0px;
    border-radius: 20px;
    min-height: calc(100vh - 300px);
}

.textCenter {
    display: flex;
    justify-content: center;
}

.categoryTitle {
    padding-top: 5px;
    padding-bottom: 15px;
    font-size: 18px;
    font-weight: 600;
}

.wordNoBreak {
    white-space: nowrap;
}

.clearBtn {
    background: rgb(255, 255, 255) !important;
    color: rgb(246, 115, 8) !important;
    border-radius: 10px !important;
    box-shadow: 2px 2px 5px rgb(107 107 107 / 10%) !important;
    text-transform: none !important;
}

.labelText {
    font-size: 16px;
    font-weight: 400;
    font-family: "Poppins";
}

input[type="datetime-local"]::-webkit-clear-button {
    -webkit-appearance: none;
    display: none;
    -webkit-appearance: none;
    appearance: none;
}

.cancel-btn {
    color: #fd8212 !important;
    border: 1px solid #fd8212 !important;
}

.theme-section {
    min-height: calc(100vh -400px);
}

.progressBarLabelContainer {
    display: flex;
    align-items: flex-start;
    gap: 10px;
}

.cloneModalButton {
    border-radius: 5px;
    text-decoration: none;
    padding: 10px 20px;
    background-color: var(--color-btn-orange);
    color: #fff;
}

.loaderContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.chipPendingTraining {
    font-weight: 600;
}

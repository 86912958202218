@import "utilities.css";
@import "variables.css";

* {
    box-sizing: border-box;
}

body,
html {
    margin: 0;
    line-height: 19px;
    font-size: 16px;
    color: var(--black-base);
    font-weight: normal;
    font-family: "Montserrat", sans-serif;
}

img {
    max-width: 100%;
    height: auto;
}

a {
    outline: none;
}

.apply_button {
    background-color: var(--color-btn-orange) !important;
}

/* .form-control, .form-select {
  box-shadow: 0px 0px 0px 1px #eaeaea;
  border: 0;
  border-bottom: 1px solid #eaeaea;
} */

.material-symbols-outlined {
    font-variation-settings:
        "FILL" 0,
        "wght" 400,
        "GRAD" 200,
        "opsz" 48;
    font-size: 1.5rem;
    display: flex;
}

/* input {
  font-family: 'Montserrat', sans-serif; 
} */

body > iframe {
    display: none;
}

.header-top-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.header-top-section .notify {
    position: relative;
}

.header-top-section svg {
    cursor: pointer;
    font-size: 2em;
    color: var(--color-base);
}

.header-top-section .notify span {
    background: red;
    width: 15px;
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
    top: 0;
    border-radius: 50%;
    color: #fff;
    font-size: 0.6em;
    font-weight: 500;
}

.chat_title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.chat_title a {
    border-radius: 5px;
    /* font-size: 0.8em; */
    text-decoration: none;
    padding: 10px 20px;
    background-color: var(--color-btn-orange);
    color: #fff;
}

.chat_title a svg {
    font-size: 1.2em;
}

.chat_title img {
    width: 40px;
    height: 40px;
    cursor: pointer;
    border-radius: 50px;
}

.chat_add_button {
    height: 45px;
    background-color: var(--color-btn-orange) !important;
    color: var(--color-white) !important;
}

.chat_gray_button {
    height: 45px;
    background-color: gray !important;
    color: var(--color-white) !important;
}

.chat_title button {
    color: #333547;
    border-color: #333547;
    border: none;
}

.chat_title button:hover {
    background-color: #626ed4;
    color: #fff;
    border-color: #fff;
}

.rmsc .dropdown-container {
    height: 40px;
    border: 0;
    border-bottom: 1px solid;
    border-radius: 0;
    font-size: 14px;
}

.rmsc .dropdown-container:focus-within {
    box-shadow: none;
    border: 0;
    border-bottom: 1px solid var(--color-gray);
}

.rmsc .dropdown-heading {
    padding: 0;
}

.rmsc .gray {
    color: var(--black-300);
}

.save-btn {
    background-color: var(--color-btn-orange) !important;
    color: var(--color-white) !important;
    width: 35%;
    height: 50px;
    border-radius: 0;
    margin-top: 20px;
}

.content-save-btn {
    background-color: var(--color-btn-orange) !important;
    color: var(--color-white) !important;
    width: auto;
    height: 40px;
    border-radius: 0;
    margin-top: 20px;
}

.content-cancel-btn {
    border: 0.1px solid var(--color-btn-orange) !important;
    color: var(--color-btn-orange) !important;
}

.addNewBtnDisplay {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.inputWidth {
    width: 14%;
}

.inputMargin {
    margin-left: 15px;
}

.contentBtnDisplay {
    display: flex;
    justify-content: end;
}

.submitBtnDisplay {
    display: flex;
    justify-content: space-between;
}

.btnDisplay {
    display: flex;
    justify-content: end;
    align-items: center;
    padding: 0px 20px;
}

.inputPadding {
    padding-left: 25px;
}

.qaBtnDisplay {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    align-items: center;
    margin-top: 100px;
    padding: 0px 20px;
}

.copy-btn {
    background-color: var(--color-blue) !important;
    color: var(--color-white) !important;
    height: 40px;
    border-radius: 0;
}

.MuiBackdrop-root {
    background-color: var(--color-white) !important;
    opacity: 0.1 !important;
}

.MuiDialogTitle-root {
    background-color: var(--color-white);
    color: var(--color-blue);
}

.empty_inner {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    border: 2px dashed var(--color-darker-gray);
    border-radius: 10px;
    background-color: var(--color-white);
    color: var(--black-300);
    font-weight: 600;
    cursor: pointer;
    /* height: 120px; */
}

.others p {
    margin-bottom: 0;
    font-size: 0.8em;
    font-weight: 600;
}

.add-project label {
    /* margin-bottom: 5px; */
    margin-top: 6px;
    font-size: 14px;
    font-weight: 500;
}

.add-project .form-control,
.add-project .form-select {
    height: auto;
    border: 0;
    padding: 0;
    font-size: 14px;
    color: var(--black-300);
    border-bottom: 1px solid var(--color-gray);
    border-radius: 0;
}

.add-project .form-control:focus,
.add-project .form-select:focus {
    box-shadow: none;
}

.__inner {
    padding: 15px;
    border-radius: 10px 10px 0 0;
    background-color: var(--color-btn-orange);
    color: #ffffff;
}

.name {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.name h2 {
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 500;
    text-transform: capitalize;
}

.name h2 .MuiSvgIcon-root {
    width: 20px;
    vertical-align: sub;
    margin-right: 10px;
    font-size: 1.2em;
    fill: var(--color-btn-orange);
}

.name .MuiSvgIcon-root {
    font-size: 2em;
    width: auto;
}

.chips {
    display: flex;
    gap: 10px;
    margin-top: 10px;
}

.chips .MuiChip-filled {
    background-color: var(--color-darker-gray);
    color: var(--color-blue-light);
    font-weight: 600;
}

.__notification-area {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 58px;
    background: var(--color-white);
    border-radius: 0 0 10px 10px;
    padding: 15px;
    box-shadow: 0 2px 5px 0px var(--color-darker-gray);
}

.icons {
    display: flex;
    gap: 10px;
    margin-top: 5px;
}

.icons span.counts {
    background-color: #e1e1e1;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* font-size: 0.6em; */
    padding: 2px;
    margin-left: -20px;
    z-index: 1;
    border: 1px solid var(--color-darker-gray);
}

.icons span.faqCounts {
    background-color: #e1e1e1;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* font-size: 0.6em; */
    padding: 2px;
    margin-left: -10px;
    z-index: 1;
    border: 1px solid var(--color-darker-gray);
}

.icons .MuiSvgIcon-root {
    color: var(--color-blue-light);
    vertical-align: text-bottom;
}

.centerCopyBtn {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.chatbotIcon {
    height: 30px;
    width: 100%;
}

.textElipses {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.icons .MuiSvgIcon-root {
    width: 100%;
    height: 40px;
}

.p10 {
    padding: 10px;
}

.promptIcon {
    background-color: #ccc;
    padding: 5px;
    border-radius: 5px;
}

.cursor-pointer {
    cursor: pointer;
}

.textAlign {
    text-align: left;
    word-wrap: break-word;
}

.paddingLeft20 {
    padding-left: 20px;
}

.ml-5 {
    margin-left: 5px;
}

.webHookTitle {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
}

.mr-10 {
    margin-right: 10px;
}

.card-box-shadow {
    box-shadow: 0px 0px 0px 1px #eaeaea;
}

.capitalize {
    text-transform: capitalize;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

.webHookSection {
    min-height: calc(100vh - 300px);
}

.storage-box {
    width: 90%;
    background: #fff;
    border-radius: 10px;
    bottom: 10px;
    transform: translateX(6%);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 10px;
    margin-bottom: 15px;
}

.storage-box .circle-border {
    position: relative;
    text-align: center;
    width: 75px;
    height: 75px;
    border-radius: 100%;
    background-color: var(--color-btn-orange);
    background-image: linear-gradient(162deg, transparent 90%, #f0f0f0 90%),
        linear-gradient(90deg, #f0f0f0 50%, transparent 0%);
}

.storage-box .circle {
    position: relative;
    top: 5px;
    left: 5px;
    text-align: center;
    width: 65px;
    height: 65px;
    border-radius: 100%;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.storage-box .circle h5 {
    margin-bottom: 0;
}

.storage-box p {
    white-space: pre-wrap;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
}

.storage-box a {
    color: var(--color-btn-orange);
    text-decoration: none;
    font-size: 14px;
    font-weight: 500;
}

#customized-dialog-title button {
    height: 30px;
    width: 30px;
    background-color: var(--color-btn-orange);
    color: var(--color-white);
}

.theme-button {
    position: fixed;
    top: 50%;
    right: 0;
    transform: rotate(-90deg);
    padding: 15px;
    border: 0;
    background-color: var(--color-btn-orange);
    color: #fff;
}

.colorsDrawer {
    background: var(--color-btn-orange) !important;
    color: var(--color-white) !important;
    height: 30px;
    width: 30px;
}

.theme-cards {
    padding: 20px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
}

.theme-cards .inner {
    width: 100%;
    aspect-ratio: 1 / 1;
    background: linear-gradient(180deg, rgba(242, 237, 253, 1) 55%, rgba(253, 233, 208, 1) 100%);
    border-radius: 50%;
    cursor: pointer;
}

.theme-cards .inner:nth-child(2) {
    background: linear-gradient(180deg, rgb(251, 191, 255) 55%, rgba(208, 244, 253, 1) 100%);
}

.theme-cards .inner:nth-child(3) {
    background: linear-gradient(0deg, rgba(125, 253, 255, 1) 0%, rgba(255, 208, 108, 1) 100%);
}

.theme-cards .inner:nth-child(4) {
    background: linear-gradient(90deg, rgba(223, 176, 255, 1) 0%, rgba(255, 203, 203, 1) 52%);
}

.theme-cards .inner:nth-child(5) {
    background: linear-gradient(90deg, rgba(255, 216, 216, 1) 0%, rgb(196, 255, 239) 52%);
}

.form-control,
.form-select {
    border: 0;
    border-radius: 0;
    border-bottom: 1px solid var(--color-chat-left);
    background-color: transparent;
}

.form-control:focus,
.form-select:focus {
    box-shadow: none;
    border-bottom: 1px solid var(--color-btn-orange);
    background-color: transparent;
}

.form-control:disabled,
.form-select:disabled {
    box-shadow: none;
    border-bottom: 1px solid var(--color-chat-left);
    background-color: transparent;
}
